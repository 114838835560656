.headerLeft{
  flex-grow: 1;
  margin-left: 26.5px;
  .marketText{
    margin-right: 5px;
    &.activityTag {
      &::after {
        width: calc(24px * 1.2);
        height: calc(14px * 1.2);
        text-align: center;
        content: 'HOT';
        background-color: #f0506c;
        border-radius: calc(4px * 1.2);
        color: #fff;
        font-family: DINPro-Medium;
        font-weight: 500;
        line-height: calc(14px * 1.2);
        letter-spacing: 0;
        font-size: 12px;
        display: inline-block;
        transform: scale(calc(10 / 12));
      }
    }
  }
}

.headerLeft>span,.postactiveContain span{
  display: inline-block;
  margin: 0 16px;
  font-size: 16px;
  color: #000000;
}
.nextIconContainer {
  padding: 16px 20px 36px!important;
}


.headerLeft span a{

  color: #000000;
}
.headerLeft span:hover,
.headerLeft span a:hover {
  color: #6F40EE;
  cursor: pointer;
}
.headerLeft .icon {
  margin: 0;
  margin-right: 2px;
}
.headerLeft>div{
  display: inline-block;
}
.headerLeft :global .ant-popover-inner-content{
  max-width: 900px;
}
.guidebtn{
   border: none;
   box-shadow: none;
   margin: 0;
   padding: 0;
   position: relative;
   top: 3px;
   margin-right: 8px;
}

.guidebtn>span,.postActive>span{
   margin: 0;
}
.guidebtn>span :global .anticon,.postActive>span :global .anticon{
   margin: 0;
   color: #434B5F;
}
.guidebtn>span :global .anticon:first-child,.postActive>span :global .anticon:first-child{
   margin: 0;
   color: #434B5F;
   margin-right: 4px;
}

.focus{
  :global{
    .anticon{
      color: #6f40ee !important;
    }
  }
  // background: #6f40ee;
}
.guidebtn>span :global .anticon:nth-child(2),.postActive>span :global .anticon:nth-child(2){
   font-size: 10px;
}
.newsContain{
  position: relative;
}
// TODO 目前直接使用文字后续可能换成图片切换暂时保留
// .news{
//   background-image: url(~@images/news_regular.webp);
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position-x:center;
//   display: inline-block;
//   width: 75px;
//   height: 70px;
//   position: absolute;
//   top: -40px;
//   left: -5px;
//   display: inline-block;
// }
// .news:hover{
//   background-image: url(~@images/news_hover.webp);
// }
.marketbtn{
  border: none;
  font-size: 16px;
  color: #1F2126;
  margin: 0;
  padding: 0;
}
.news{
  font-size: 18px;
}
@media (max-width: 1500px) {
  html[lang='en']{
  .headerLeft>span,.postactiveContain span,.marketbtn .marketText,.news
    {
        display: inline-block;
        margin: 0 5px;
        font-size: 14px;
        color: #000000;
    }
  }
}
@media (max-width: 1350px) {
    .headerLeft>span,.postactiveContain span,.marketbtn .marketText,.news
    {
        display: inline-block;
        margin: 0 5px;
        font-size: 12px;
        color: #000000;
    }
}
@media (max-width: 1050px) {
  .headerLeft>span,.postactiveContain span,.marketbtn .marketText,.news{
      display: inline-block;
      margin: 0 5px;
      font-size: 14px;
      color: #000000;
  }
  html[lang='en'] {
    .headerLeft>span,.postactiveContain span,.marketbtn .marketText,.news{
      display: inline-block;
      margin: 0 5px;
      font-size: 12px;
      color: #000000;
  }
  }
}