@import '../../navbar.common.less';

.childrenMenuItem {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  font-size: 14px;
  color: #333333;
  font-family: 'DINPro';
  font-weight: 500;
  .itemContent {
    display: flex;
    align-items: center;
  }
}
.h60 {
  height: 60px;
}
.childrenMenuItem:hover {
  color: #6f40ee;
  cursor: pointer;
  background-color: rgba(246, 248, 251, 0.45);
  border-radius: 4px;
  .childrenMenuIcon {
    color: #6f40ee;
  }
}
.childrenMenu {
  padding: 12px 0;
}
.padding20 {
  padding: 0 20px;
}
.logout {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.04);
}
.childrenMenuIcon {
  font-size: 20px;
  color: #696f7f;
  margin-right: 8px;
}
.mr12 {
  margin-right: 12px;
}
.nextIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
  // margin-top: 20px;
  .arrowRight {
    display: none;
  }
  // &:hover {
  //   .arrowRight {
  //     display: block;
  //   }
  // }
}

.popoverCollaps {
  background: white;
  border: none;
  padding: 0 10px;
  :global {
    .ant-collapse-header {
      padding: 10px !important;
      .history {
        font-size: 20px;
        color: #696f7f;
        margin-right: 10px;
      }
    }
    .ant-collapse-header:hover {
      background: rgba(246, 248, 251, 0.7);
      color: #6f40ee;
      border-radius: 8px !important;
      .history {
        color: #6f40ee !important;
      }
    }
    .ant-collapse-header-text {
      line-height: 20px;
      display: flex;
    }
    .ant-collapse-arrow {
      position: relative;
      right: 10px !important;
      font-size: 10px !important;
    }
    .ant-collapse-content,
    .ant-collapse-item {
      border: none;
    }
    .ant-collapse-content-box {
      padding: 0;
    }
    .ant-collapse-item {
      border: none !important;
    }
  }
  .title,
  .text {
    line-height: 20px;
  }
  .text {
    padding: 10px 37px;
    font-size: 14px;
    color: #333333;
  }
  .text:hover {
    background: rgba(246, 248, 251, 0.7);
    color: #6f40ee;
    border-radius: 8px;
  }
}
.collapseFontSize {
  font-size: 12px;
  color: #696f7f;
}
.btnArrow {
  font-size: 10px;
  margin-left: 5px;
  transition: 0.2s !important;
  transform: rotate(0);
}
.arrowRotate {
  transform: rotate(180deg);
}
.languagePopver {
  justify-content: space-between;
}
.childrenMenuItemLanguage .languagePopverIcon {
  display: block;
  color: #6f40ee;
}
.languagePopverIcon {
  display: none;
}
.childrenMenuItemLanguage {
  color: #6f40ee;
}
.hoverColor:focus,
.hoverColor:hover {
  color: #6f40ee;
}
.childrenMenuPop {
  padding-top: 18px !important;
}
.childrenMenuPop :global .ant-popover-title {
  border-bottom: none;
  padding: 12px 0 0 0;
}
.flexCenter,
.flexCenter > div {
  display: flex !important;
  align-items: center;
}
.childrenMenuItemTips {
  padding-left: 28px;
  font-size: 12px;
  line-height: 17px;
  color: #696f7f;
}
.childrenMenuItemContainer {
  margin-top: 16px;
  padding: 12px;
  .childrenMenuItem {
    height: unset;
  }
  &:hover {
    color: #6f40ee;
    cursor: pointer;
    background-color: rgba(246, 248, 251, 0.45);
    border-radius: 4px;
    & > .childrenMenuItem > .arrowRight {
      display: block;
      color: #6f40ee;
    }
  }
}
